<template>
	<BaseEditControls
		:block-id="blockId"
		:block-ref="blockRef"
		:button-title="$t('builder.editSocialIcons.title')"
	>
		<ZyroPopupCard
			type="editor"
			:title="$t('builder.editSocialIcons.popupTitle')"
			:tabs="$options.tabs"
			:current-tab="currentTab"
			@change="currentTab = $event"
			@close="handlePopupCardClose"
		>
			<Component :is="currentTab.component" />
		</ZyroPopupCard>
	</BaseEditControls>
</template>

<script>
import {
	mapGetters,
	mapMutations,
	mapActions,
} from 'vuex';

import BaseEditControls from '@/components/builder-controls/BaseEditControls.vue';
import i18n from '@/i18n/setup';

import EditSocialIconsTabIcon from './EditSocialIconsTabIcon.vue';
import EditSocialIconsTabLayout from './EditSocialIconsTabLayout.vue';
import EditSocialIconsTabLinks from './EditSocialIconsTabLinks.vue';

const tabs = [
	{
		title: i18n.t('common.links'),
		component: 'EditSocialIconsTabLinks',
	},
	{
		title: i18n.t('common.icons'),
		component: 'EditSocialIconsTabIcon',
	},
	{
		title: i18n.t('common.layout'),
		component: 'EditSocialIconsTabLayout',
	},
];

export default {
	components: {
		BaseEditControls,
		EditSocialIconsTabIcon,
		EditSocialIconsTabLayout,
		EditSocialIconsTabLinks,
	},
	props: {
		blockId: {
			type: String,
			required: true,
		},
		blockRef: {
			type: HTMLElement,
			required: true,
		},
	},
	data() {
		return { currentTab: this.$options.tabs[0] };
	},
	computed: mapGetters(['currentElement']),
	methods: {
		...mapActions(['removeCurrentElement']),
		...mapActions('gui', ['setEditMode']),
		...mapMutations(['setCurrentBlock']),
		handlePopupCardClose() {
			if (this.currentElement.links.length === 0) {
				this.setCurrentBlock({
					blockId: this.blockId,
					blockRef: this.blockRef,
				});
				this.removeCurrentElement();
			}

			this.$emit('close');
		},
	},
	tabs,
};
</script>
