<template>
	<EditableItemsList
		ref="items"
		:items="editableItems"
		:placeholder="$t('builder.editSocialIcons.tabLinks.placeholder')"
		:validate-value="validator"
		@edit="editLink"
		@update-items="updateItems"
	>
		<template slot="header">
			<EditableItemsAddButton
				:button-text="$t('builder.editSocialIcons.tabLinks.addNew')"
				:placeholder="$t('builder.editSocialIcons.tabLinks.placeholder')"
				:validate-value="validator"
				data-qa="edit-social-icons-popup-links-button-add"
				@add="addLink"
			/>
		</template>
		<template
			#item-button="{ item, index, startEditingItem }"
		>
			<ZyroPopup
				type="no-padding"
				:show-close-button="false"
				:offset="popupOffset"
				:on-toggle="handlePopupToggle"
			>
				<template #trigger>
					<ZyroButton
						icon="settings"
						:title="$t('common.settings')"
						data-qa="edit-social-icons-popup-links-settings-open"
					/>
				</template>
				<div class="links__link-settings">
					<ZyroButton
						icon-left="edit"
						data-qa="edit-social-icons-popup-links-link-edit"
						@click="startEditingItem(index, item)"
					>
						{{ $t('common.edit') }}
					</ZyroButton>

					<ZyroButton
						class="links__link-remove"
						theme="plain"
						color="black"
						icon-left="trash"
						:title="$t('common.delete')"
						data-qa="edit-social-icons-popup-links-link-remove"
						@click="removeLink(index)"
					>
						{{ $t('common.delete') }}
					</ZyroButton>
				</div>
			</ZyroPopup>
		</template>
	</EditableItemsList>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import { availableIcons } from '@/assets/icons/brands/info';
import EditableItemsAddButton from '@/components/reusable-components/editable-items-list/-partials/EditableItemsAddButton.vue';
import EditableItemsList from '@/components/reusable-components/editable-items-list/EditableItemsList.vue';
import { cloneDeep } from '@/utils/object';
import { getValidUrl } from '@/utils/urlValidators';

import symbolImporter from './symbolImporter';

export default {
	components: {
		EditableItemsList,
		EditableItemsAddButton,
	},
	data() {
		return {
			id: '',
			currentElementBeforeEdit: null,
			error: '',
			popupOpensToBottom: false,
		};
	},
	computed: {
		...mapState(['currentElementId']),
		...mapGetters(['currentElement']),
		popupOffset() {
			return this.popupOpensToBottom ? {
				top: 20,
				right: 13,
			} : {
				bottom: 0,
				right: 16,
			};
		},
		editableItems() {
			return this.currentElement.links.map((item) => {
				const {
					link,
					icon,
				} = item;

				return {
					originalData: { ...item },
					name: decodeURI(link),
					iconLocation: icon ? 'brands' : '',
					icon: icon ? `${icon}-brands` : 'link',
				};
			});
		},
	},
	mounted() {
		this.id = this.currentElementId;
		this.currentElementBeforeEdit = cloneDeep(this.currentElement);
	},
	beforeDestroy() {
		this.pushElementDataToHistory({
			elementId: this.id,
			oldData: this.currentElementBeforeEdit,
		});
	},
	methods: {
		...mapMutations([
			'pushElementDataToHistory',
			'setElementData',
		]),
		async getIconByURL(link) {
			const urlStartRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)/;
			const linkWithoutStart = link.replace(urlStartRegex, '');

			const icon = availableIcons.find((iconObject) => iconObject
				.identifiers.some((identifier) => {
					const pattern = new RegExp(`^${identifier}`, 'i');

					return pattern.test(linkWithoutStart);
				}));

			const iconSymbol = await symbolImporter.importSymbol(icon && icon.iconName);
			const iconSvg = iconSymbol.replace(/(<|<\/)symbol(>|)/gi, '$1svg$2');

			return {
				...icon,
				svg: iconSvg,
			};
		},
		parseLink(link) {
			const {
				url,
				isUrlValid,
			} = getValidUrl(link);
			const parsedLink = url.includes('http') ? url : `https:${url}`;

			return {
				url: parsedLink,
				isUrlValid,
			};
		},
		validator(link) {
			const {
				url,
				isUrlValid,
			} = this.parseLink(link);

			if (!link.trim()) {
				return {
					isValid: false,
					error: this.$t('validate.emptyValue'),
				};
			}

			const isValid = url.length > 5 && isUrlValid;

			return {
				isValid,
				error: !isValid ? this.$t('builder.editSocialIcons.error.invalidUrl') : '',
			};
		},
		updateItems(links) {
			this.setElementData({ data: { links: links.map(({ originalData }) => ({ ...originalData })) } });
		},
		async addLink(newLink) {
			const { url } = getValidUrl(newLink);
			const link = url.includes('http') ? url : `https:${url}`;

			const icon = await this.getIconByURL(link);
			const links = [
				...this.currentElement.links,
				{
					link,
					icon: icon.iconName || '',
					svg: icon.svg,
				},
			];

			this.setElementData(({ data: { links } }));
		},
		removeLink(index) {
			const links = [...this.currentElement.links];

			links.splice(index, 1);
			this.setElementData(({ data: { links } }));
		},
		async editLink({
			newValue,
			index,
		}) {
			const { url: link } = this.parseLink(newValue.name);
			const icon = await this.getIconByURL(link);
			const links = [...this.currentElement.links];
			const currentItem = { ...links[index] };

			links[index] = {
				...currentItem,
				link,
				icon: icon.iconName || '',
				svg: icon.svg,
			};
			this.setElementData(({ data: { links } }));
		},
		handlePopupToggle(isOpen, popupTriggerPosition) {
			if (!isOpen) {
				return;
			}

			const {
				height: scrollAreaHeight,
				top: scrollAreaTop,
			} = this.$refs.items.$refs.items.getBoundingClientRect();
			const popupTopPositionInScrollArea = popupTriggerPosition.top - scrollAreaTop;
			/*
			 * By default, popup should be opened on the bottom of the item,
			 * unless it's not enough space for popup to fit.
			 * Example:
			 * Open popup of the last item in scroll and it won't be visible, you'd have to scroll down
			 *
			 * Edge case: if user screen has an unnatural height screen open the menu to the bottom.
			 */
			const RESERVED_SPACE_FOR_POPUP = 250;

			this.popupOpensToBottom = scrollAreaHeight < RESERVED_SPACE_FOR_POPUP
				|| scrollAreaHeight > popupTopPositionInScrollArea + RESERVED_SPACE_FOR_POPUP;
		},
	},
};
</script>

<style lang="scss" scoped>
.links {
	&__link-settings {
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
}
</style>
