<template>
	<div class="layout">
		<ZyroFieldToggle
			:id="`${id}-toggle`"
			:checked="showIconsVertically"
			:label="$t('builder.editSocialIcons.tabLayout.displayVertically')"
			data-qa="edit-social-icons-popup-layout-toglefield-vertical"
			@change="showIconsVertically = !showIconsVertically"
		/>
		<ZyroSeparator />
		<ZyroLabel class="layout__spacing-label">
			{{ $t('builder.editSocialIcons.tabLayout.iconSpacing') }}
		</ZyroLabel>
		<ZyroIconControls
			:current.sync="iconSpacing"
			:icons="$options.iconsSpacing"
			:class="{ 'layout__spacing--rotated': showIconsVertically }"
			:toggleable="false"
			data-qa="edit-social-icons-popup-layout-controls-spacing"
		/>
		<template v-if="!iconSpacing">
			<ZyroLabel>
				{{ $t('builder.editSocialIcons.tabLayout.spaceBetween') }}
			</ZyroLabel>
			<ZyroRange
				v-model="spaceBetweenIcons"
				class="layout__spacing-input"
				type="number"
				min="0"
				step="1"
				max="64"
				data-qa="edit-social-icons-popup-layout-rangefield-space-between"
			/>
		</template>
		<ZyroSeparator />
		<ZyroLabel class="layout__alignment-label">
			{{ $t('builder.editButton.tabLayoutLabel') }}
		</ZyroLabel>
		<div class="layout__alignment">
			<ZyroIconControls
				v-if="showIconsVertically || !iconSpacing"
				:toggleable="false"
				:current.sync="align"
				:icons="$options.iconsAlign"
				data-qa="edit-social-icons-popup-layout-controls-align"
			/>
			<ZyroIconControls
				v-if="!showIconsVertically || !iconSpacing"
				:toggleable="false"
				:current.sync="justify"
				:icons="$options.iconsJustify"
				data-qa="edit-social-icons-popup-layout-controls-justify"
			/>
		</div>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import { cloneDeep } from '@/utils/object';

const iconsSpacing = [
	{
		value: 'space-between',
		icon: 'align-space-between-h',
	},
	{
		value: 'space-around',
		icon: 'align-space-around-h',
	},
	{
		value: '',
		icon: 'align-fixed',
	},
];

const iconsAlign = [
	{
		value: 'flex-start',
		icon: 'align-left-h',
	},
	{
		value: 'center',
		icon: 'align-middle-h',
	},
	{
		value: 'flex-end',
		icon: 'align-right-h',
	},
];
const iconsJustify = [
	{
		value: 'flex-start',
		icon: 'align-top',
	},
	{
		value: 'center',
		icon: 'align-middle',
	},
	{
		value: 'flex-end',
		icon: 'align-bottom',
	},
];

export default {
	iconsAlign,
	iconsSpacing,
	iconsJustify,
	data() {
		return {
			id: '',
			currentElementBeforeEdit: null,
		};
	},
	computed: {
		...mapState(['currentElementId']),
		...mapGetters(['currentElement']),
		showIconSpacing() {
			if (this.showIconsVertically) {
				return !this.justify;
			}

			return !this.align;
		},
		showIconsVertically: {
			get() {
				return this.currentElement.settings.styles['icon-direction'] === 'column';
			},
			set(newValue) {
				if (newValue && !this.align) {
					this.align = this.$options.iconsAlign[1].value;
				} else if (!this.justify) {
					this.justify = this.$options.iconsJustify[1].value;
				}

				this.setElementData({ data: { settings: { styles: { 'icon-direction': newValue ? 'column' : 'row' } } } });
			},
		},
		spaceBetweenIcons: {
			get() {
				return Number.parseInt(this.currentElement.settings.styles['space-between-icons'], 10);
			},
			set(event) {
				this.setElementData({ data: { settings: { styles: { 'space-between-icons': `${event.target.value}px` } } } });
			},
		},
		iconSpacing: {
			get() {
				return this.currentElement.settings.styles['icon-spacing'];
			},
			set(newValue) {
				if (this.showIconsVertically) {
					this.justify = !newValue ? this.$options.iconsJustify[1].value : '';
				} else {
					this.align = !newValue ? this.$options.iconsAlign[1].value : '';
				}

				this.setElementData({ data: { settings: { styles: { 'icon-spacing': newValue } } } });
			},
		},
		align: {
			get() {
				return this.currentElement.settings.styles.align;
			},
			set(newValue) {
				this.setElementData({ data: { settings: { styles: { align: newValue } } } });
			},
		},
		justify: {
			get() {
				return this.currentElement.settings.styles.justify;
			},
			set(newValue) {
				this.setElementData({ data: { settings: { styles: { justify: newValue } } } });
			},
		},
	},
	mounted() {
		this.id = this.currentElementId;
		this.currentElementBeforeEdit = cloneDeep(this.currentElement);
	},
	beforeDestroy() {
		this.pushElementDataToHistory({
			elementId: this.id,
			oldData: this.currentElementBeforeEdit,
		});
	},
	methods: {
		...mapMutations([
			'pushElementDataToHistory',
			'setElementData',
		]),
	},
};
</script>

<style lang="scss" scoped>
.layout {
	display: flex;
	flex-direction: column;

	&__spacing {
		&-label {
			margin-top: 16px;
		}

		&-input {
			margin-bottom: 16px;
		}

		&--rotated {
			::v-deep svg {
				transform: rotateZ(90deg);
			}
		}
	}

	&__alignment {
		display: flex;

		> *:not(:last-child) {
			margin-right: 24px;
		}

		&-label {
			margin: 16px 0 8px;
		}
	}
}
</style>
