<template>
	<div class="icon">
		<ZyroFieldRange
			v-model="iconSize"
			type="number"
			min="10"
			step="1"
			max="100"
			data-qa="edit-social-icons-popup-icons-rangefield-size"
			:label="$t('builder.editSocialIcons.tabIcon.size')"
		/>
		<ZyroSeparator />
		<div class="icon__color">
			<ZyroLabel>{{ $t('builder.editSocialIcons.tabIcon.color') }}</ZyroLabel>
			<ZyroColorPicker
				v-model="iconColor"
				data-qa="edit-social-icons-popup-icons-colorfield"
				:default-mode="$options.COLOR_PICKER_MODE_FULL"
			/>
		</div>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import { COLOR_PICKER_MODE_FULL } from '@/components/global/zyro-color-picker/constants';
import { useElementContrast } from '@/use/useElementContrast';
import { lightenDarkenColor } from '@/utils/lightenDarkenColor';
import { cloneDeep } from '@/utils/object';

export default {
	COLOR_PICKER_MODE_FULL,
	setup() {
		const { getLighterColorShade } = useElementContrast();

		return { getLighterColorShade };
	},
	data() {
		return {
			id: '',
			currentElementBeforeEdit: null,
		};
	},
	computed: {
		...mapState(['currentElementId']),
		...mapGetters(['currentElement']),
		iconSize: {
			get() {
				return Number.parseInt(this.currentElement.settings.styles['icon-size'], 10);
			},
			set(event) {
				this.setElementData({ data: { settings: { styles: { 'icon-size': `${event.target.value}px` } } } });
			},
		},
		iconColor: {
			get() {
				return this.currentElement.settings.styles['icon-color'];
			},
			set(newValue) {
				this.setElementData({
					data: {
						settings: {
							styles: {
								'icon-color': newValue,
								'icon-color-hover': this.getLighterColorShade(newValue),
							},
						},
					},
				});
			},
		},
	},
	mounted() {
		this.id = this.currentElementId;
		this.currentElementBeforeEdit = cloneDeep(this.currentElement);
	},
	beforeDestroy() {
		this.pushElementDataToHistory({
			elementId: this.id,
			oldData: this.currentElementBeforeEdit,
		});
	},
	methods: {
		...mapMutations([
			'pushElementDataToHistory',
			'setElementData',
		]),
		lightenDarkenColor,
	},
};
</script>

<style lang="scss" scoped>
.icon {
	&__color {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 16px;
	}
}
</style>
