export const availableIcons = [
	{
		iconName: 'airbnb',
		brandColor: '#fd5c63',
		identifiers: ['airbnb'],
	},
	{
		iconName: 'amazon',
		brandColor: '#ff9900',
		identifiers: ['amazon'],
	},
	{
		iconName: 'amazon-pay',
		brandColor: '#ff9900',
		identifiers: [
			'amazon-pay',
			'pay.amazon',
		],
	},
	{
		iconName: 'bandcamp',
		brandColor: '',
		identifiers: [
			'(.*?)bandcamp',
			'bandcamp',
		],
	},
	{
		iconName: 'reddit',
		brandColor: '#ff9900',
		identifiers: [
			'reddit',
			'redd.it',
		],
	},
	{
		iconName: 'app-store',
		brandColor: '',
		identifiers: ['app-store'],
	},
	{
		iconName: 'app-store-ios',
		brandColor: '',
		identifiers: ['app-store-ios'],
	},
	{
		iconName: 'apple',
		brandColor: '#000000',
		identifiers: ['apple'],
	},
	{
		iconName: 'apple-podcasts',
		brandColor: '#000000',
		identifiers: ['podcasts.apple'],
	},
	{
		iconName: 'apple-pay',
		brandColor: '',
		identifiers: [
			'apple-pay',
			'pay.apple',
			'payments.apple',
		],
	},
	{
		iconName: 'audible',
		brandColor: '',
		identifiers: ['audible'],
	},
	{
		iconName: 'behance',
		brandColor: '#1769ff',
		identifiers: ['behance'],
	},
	{
		iconName: 'bitbucket',
		brandColor: '#205081',
		identifiers: ['bitbucket'],
	},
	{
		iconName: 'bitcoin',
		brandColor: '',
		identifiers: ['bitcoin'],
	},
	{
		iconName: 'btc',
		brandColor: '',
		identifiers: ['btc'],
	},
	{
		iconName: 'codepen',
		brandColor: '#000000',
		identifiers: ['codepen'],
	},
	{
		iconName: 'discord',
		brandColor: '#7289da',
		identifiers: ['discord'],
	},
	{
		iconName: 'dribbble',
		brandColor: '#ea4c89',
		identifiers: ['dribbble'],
	},
	{
		iconName: 'dropbox',
		brandColor: '#007ee5',
		identifiers: ['dropbox'],
	},
	{
		iconName: 'ebay',
		brandColor: '',
		identifiers: ['ebay'],
	},
	{
		iconName: 'etsy',
		brandColor: '#d5641c',
		identifiers: ['etsy'],
	},
	{
		iconName: 'facebook',
		brandColor: '#4267B2',
		identifiers: ['facebook'],
	},
	{
		iconName: 'facebook-f',
		brandColor: '#4267B2',
		identifiers: ['facebook-f'],
	},
	{
		iconName: 'facebook-messenger',
		brandColor: '#4267B2',
		identifiers: ['facebook-messenger'],
	},
	{
		iconName: 'facebook-square',
		brandColor: '#4267B2',
		identifiers: ['facebook-square'],
	},
	{
		iconName: 'github',
		brandColor: '',
		identifiers: ['github'],
	},
	{
		iconName: 'goodreads-g',
		brandColor: '#553b08',
		identifiers: [
			'goodreads-g',
			'goodreads',
		],
	},
	{
		iconName: 'google',
		brandColor: '',
		identifiers: ['google'],
	},
	{
		iconName: 'google-play',
		brandColor: '',
		identifiers: ['google-play'],
	},
	{
		iconName: 'instagram',
		brandColor: '',
		identifiers: ['instagram'],
	},
	{
		iconName: 'itunes',
		brandColor: '',
		identifiers: ['itunes'],
	},
	{
		iconName: 'itunes-note',
		brandColor: '',
		identifiers: ['itunes-note'],
	},
	{
		iconName: 'lastfm',
		brandColor: '#d51007',
		identifiers: ['lastfm'],
	},
	{
		iconName: 'linkedin-in',
		brandColor: '',
		identifiers: [
			'linkedin',
			'linkedin.com',
			'linked.in',
			'in.linkedin',
			'linkedin.com',
		],
	},
	{
		iconName: 'medium-m',
		brandColor: '',
		identifiers: ['medium-m'],
	},
	{
		iconName: 'mixcloud',
		brandColor: '#273a4b',
		identifiers: ['mixcloud'],
	},
	{
		iconName: 'paypal',
		brandColor: '',
		identifiers: ['paypal'],
	},
	{
		iconName: 'pinterest-p',
		brandColor: '#bd081c',
		identifiers: [
			'pinterest',
			'pinterest-p',
		],
	},
	{
		iconName: 'pinterest',
		brandColor: '#bd081c',
		identifiers: [
			'pinterest-p',
			'pinterest',
		],
	},
	{
		iconName: 'skype',
		brandColor: '#00aff0',
		identifiers: ['skype'],
	},
	{
		iconName: 'slack',
		brandColor: '',
		identifiers: ['slack'],
	},
	{
		iconName: 'snapchat-ghost',
		brandColor: '#fffc00',
		identifiers: [
			'snapchat-ghost',
			'snapchat',
		],
	},
	{
		iconName: 'soundcloud',
		brandColor: '#ff8800',
		identifiers: ['soundcloud'],
	},
	{
		iconName: 'spotify',
		brandColor: '#1db954',
		identifiers: [
			'spotify',
			'open.spotify',
		],
	},
	{
		iconName: 'telegram',
		brandColor: '#0088cc',
		identifiers: [
			'telegram',
			't.me',
		],
	},
	{
		iconName: 'telegram-plane',
		brandColor: '',
		identifiers: ['telegram-plane'],
	},
	{
		iconName: 'tiktok',
		brandColor: '',
		identifiers: ['tiktok'],
	},
	{
		iconName: 'tripadvisor',
		brandColor: '#00af87',
		identifiers: ['tripadvisor'],
	},
	{
		iconName: 'tumblr',
		brandColor: '#35465c',
		identifiers: ['tumblr'],
	},
	{
		iconName: 'twitch',
		brandColor: '#6441a5',
		identifiers: ['twitch'],
	},
	{
		iconName: 'twitter',
		brandColor: '#1da1f2',
		identifiers: ['twitter'],
	},
	{
		iconName: 'imdb',
		brandColor: '',
		identifiers: ['imdb'],
	},
	{
		iconName: 'researchgate',
		brandColor: '',
		identifiers: ['researchgate'],
	},
	{
		iconName: 'vimeo-v',
		brandColor: '#1ab7ea',
		identifiers: [
			'vimeo-v',
			'vimeo',
		],
	},
	{
		iconName: 'vk',
		brandColor: '#45668e',
		identifiers: ['vk'],
	},
	{
		iconName: 'whatsapp',
		brandColor: '#25d366',
		identifiers: [
			'whatsapp',
			'wa.me',
			'api.whatsapp',
		],
	},
	{
		iconName: 'youtube',
		brandColor: '#ff0000',
		identifiers: ['youtube'],
	},
];
